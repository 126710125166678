// Generated by Framer (d03ec02)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["I1053:374018;1053:372591", "TMSBK3Xd4"];

const serializationHash = "framer-lpOdn"

const variantClassNames = {"I1053:374018;1053:372591": "framer-v-1w5k59i", TMSBK3Xd4: "framer-v-1ii07zh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 40, delay: 0, mass: 1, stiffness: 300, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Active: "TMSBK3Xd4", Inactive: "I1053:374018;1053:372591"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "I1053:374018;1053:372591"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I1053:374018;1053:372591", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapzyql5l = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("TMSBK3Xd4")
})

const onTaph5nulq = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
setVariant("I1053:374018;1053:372591")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1w5k59i", className, classNames)} data-border data-framer-name={"Inactive"} data-highlight layoutDependency={layoutDependency} layoutId={"I1053:374018;1053:372591"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTapzyql5l} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "2px", "--border-color": "var(--token-32a85a80-d312-468e-9679-2600c480ecba, rgb(40, 40, 40)) /* {\"name\":\"Black 700\"} */", "--border-left-width": "2px", "--border-right-width": "2px", "--border-style": "solid", "--border-top-width": "2px", backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} variants={{TMSBK3Xd4: {backgroundColor: "var(--token-32a85a80-d312-468e-9679-2600c480ecba, rgb(40, 40, 40))"}}} {...addPropertyOverrides({TMSBK3Xd4: {"data-framer-name": "Active", onTap: onTaph5nulq}}, baseVariant, gestureVariant)}><motion.div className={"framer-1yvtj79"} data-framer-name={"Oval"} layoutDependency={layoutDependency} layoutId={"I1053:374018;1053:372591;0:8130"} style={{backgroundColor: "var(--token-32a85a80-d312-468e-9679-2600c480ecba, rgb(40, 40, 40))", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}} variants={{TMSBK3Xd4: {backgroundColor: "var(--token-2727a40a-6355-410e-b173-94b4ce7b76c2, rgb(255, 255, 255))"}}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-lpOdn[data-border=\"true\"]::after, .framer-lpOdn [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lpOdn.framer-1apxxf6, .framer-lpOdn .framer-1apxxf6 { display: block; }", ".framer-lpOdn.framer-1w5k59i { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: visible; padding: 4px 4px 4px 4px; position: relative; width: 38px; }", ".framer-lpOdn .framer-1yvtj79 { flex: none; height: 13px; position: relative; width: 13px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-lpOdn.framer-1w5k59i { gap: 0px; } .framer-lpOdn.framer-1w5k59i > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-lpOdn.framer-1w5k59i > :first-child { margin-left: 0px; } .framer-lpOdn.framer-1w5k59i > :last-child { margin-right: 0px; } }", ".framer-lpOdn.framer-v-1ii07zh.framer-1w5k59i { justify-content: flex-end; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 21
 * @framerIntrinsicWidth 38
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"TMSBK3Xd4":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 */
const FramerEyYGsWeVR: React.ComponentType<Props> = withCSS(Component, css, "framer-lpOdn") as typeof Component;
export default FramerEyYGsWeVR;

FramerEyYGsWeVR.displayName = "01-Components/Toggle";

FramerEyYGsWeVR.defaultProps = {height: 21, width: 38};

addPropertyControls(FramerEyYGsWeVR, {variant: {options: ["I1053:374018;1053:372591", "TMSBK3Xd4"], optionTitles: ["Inactive", "Active"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerEyYGsWeVR, [])